import styled, {css} from "styled-components";
import {FaPlus} from "react-icons/fa";
import {FaSquare} from "react-icons/fa";


const ScrollWrap = styled.div`
  display: inherit;
  overflow-y: auto;
  width: 100%;
  height: ${props => props.height || "100%"};

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${props => props.theme.mainBg};
  }
`

const Wrapper = styled.div`
    border-radius: 12px;
    position: relative;
    width: 100%;
    background-color: ${props => props.theme.primaryBg};
  
  @media screen and (max-width: 480px) {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
  }
`

const ImageWrapper = styled.div`
    //position: relative;
    //top: -70px;
    left: calc(50% - 35px);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Header = styled.div`
    width: 100%;
    text-align: center;
    font-size: 1rem;
    color: ${props => props.theme.mainOrange};
    padding: 15px 0;
    border-bottom: 1px solid ${props => props.theme.color}15;
`

const PriceBoard = styled.div`
    width: 90%;
    padding: 20px 10px;
    background-color: ${props => props.theme.input};
    border-radius: 8px;
`

const ReceiveBoard = styled(PriceBoard)`
    padding: 15px 10px;
    background-color: ${props => props.theme.mainGreen}20;
`

const WalletWrapper = styled.div`
  width: 60%;
  height: 32px;
  border-radius: 12px;
  display: flex;
  background-color: ${props => props.theme.color};
`

const WalletTab = styled.div`
  width: 50%;
  height: 32px;
  transition: all 0.3s;
  border-radius: inherit;
  font-size: 0.8rem;
  cursor: pointer;
  color: ${props => props.theme.mainBg};
  ${props => props.active && css`
    background-color: ${props => props.theme.mainOrange};
    color: #191c20;
  `};
`

const Network = styled.div`
  border: 1px solid ${props => props.theme.color}80;
  background-color: ${props => props.theme.tInputBg};
  color: ${props => props.theme.color};
  margin: 5px;

  ${props => props.active && css`
    background-color: ${props => props.theme.mainBg};
    color: ${props => props.theme.mainOrange};
    border: 1px solid ${props => props.theme.color};

  `};
  cursor: pointer;
  border-radius: 12px;
  min-width: 80px;
  min-height: 28px;
  font-size: 0.8rem;
  font-family: Roboto,serif;
  display: flex;
  justify-content: center;
  align-items: center;
`

const AddIcon = styled(FaPlus)`
  color: ${props => props.theme.color};
  margin: 0 10px;
  cursor: pointer;
  position: relative;
`

const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.color}20;
`

const InvoiceWrapper = styled(ScrollWrap)`
    
    @media screen and (max-width: 768px) {
      height: 100vh;
    }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${props => props.active ? props.theme.mainOrange : props.theme.input}95;
  color: #191c20;
  min-width: 100px;
  min-height: 28px;
  font-size: 0.8rem;
  margin: ${props => props.margin || "0 10px"};
  cursor: pointer;
  transition: all 0.3s;

  &:hover{
    background-color: ${props => props.active ? props.theme.mainOrange : props.theme.input};
  }
`

const CFlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  direction: ${props => props.direction};
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "fit-content"};
`;

const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
`


const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${props => props.width};
  height: ${props => props.height};
`

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
`

const DText = styled.div`
  font-size: ${props => props.fontSize || "0.7rem"};
  color: ${props => props.color || props.theme.color};
  direction: ${props => props._type === "number" && "ltr"};
  font-family: ${props => props.type === "number" && "monospace"};
  
  @media screen and (max-width: 1400px){
    font-size: ${props => props.fontSize || "0.65rem"};
    letter-spacing: 0.8px;
  }
  
  @media screen and (max-width: 480px) {
    font-size: ${props => props.fontSize || "0.6rem"};
  }
`

const InvoiceText = styled(DText)`
  padding: 0 2px;
`

const LoaderContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 8px;
`

const Decoration = styled(FaSquare)`
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  color: ${props => props.theme.mainOrange};
`;

const Tooltip = styled.div`
  display: ${props => !props.show && "none"};
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  padding: 8px;
  background-color: ${props => props.theme.tradeBg};
  color: ${props => props.theme.color};
  font-size: 0.7rem;
  border-radius: 6px;
  border: 2px solid ${props => props.theme.color}50;
`


const CFlexStart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: ${props => props.width};
`



export {
    WalletWrapper,
    WalletTab,
    PriceBoard,
    ReceiveBoard,
    Wrapper,
    ImageWrapper,
    Header,
    Network,
    AddIcon,
    LineBreak,
    InvoiceWrapper,
    Button,
    CFlexCenter,
    FlexCenter,
    FlexStart,
    FlexEnd,
    FlexBetween,
    InvoiceText,
    LoaderContainer,
    Decoration,
    Tooltip,
    CFlexStart,
    DText
}
import { ScaleLoader } from "react-spinners";
import { SpinnerContainer } from "../../styles/common/CommonStyles";



const Loading = (props) => {

    return (
        <>
            {props.loading &&
                <SpinnerContainer {...props}>
                    <ScaleLoader
                        height={props.height || 26}
                        width={props.width || 3}
                        color="#ffc800"
                    />
                </SpinnerContainer>
            }
        </>
    )
}

export default Loading;

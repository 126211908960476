import styled from "styled-components";
import {Flex, Padding} from "../common/CommonStyles";
import {motion} from "framer-motion";
import {AiFillCheckCircle} from 'react-icons/ai'


const TranslateWrapper = styled(Padding)`
  perspective: 1000px;
  background: radial-gradient(circle, rgba(71,82,96,1) 0%, rgba(10,41,80,1) 10%, rgba(6,17,33,1) 50%);
`

const Translate = styled.div`
  transform-origin: 50% 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Img = styled.img`

  @media screen and (max-width: 768px) {
    width: 220px;
  }
`


const AMTitle = styled(motion.div)`
  font-size: 4rem;
  color: ${props => props.theme.primary};
  letter-spacing: 1.2px;
  line-height: 2px;
  line-break: anywhere;
  
  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
`



const ScrollAnimationWrapper = styled(Flex)`
  overflow: hidden;
`

const TickIcon = styled(AiFillCheckCircle)`
  color: ${props => props.theme.clientPrimary};
  margin: 0 5px;
`


const AMDesc = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export {
    TranslateWrapper,
    Translate,
    Img,
    AMTitle,
    ScrollAnimationWrapper,
    TickIcon,
    AMDesc
}
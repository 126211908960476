import axios from 'axios'
import { BASE_URL } from '../../utils/constants'

const normalFetch = (token, path) => {
	return axios.get(BASE_URL + path, {
		headers: {
			'x-auth-token': token,
		},
	})
}

export { normalFetch }

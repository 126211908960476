import {useInView} from "react-intersection-observer";
import {useAnimation} from "framer-motion";
import {useEffect} from "react";


const useScrollAnimation = (threshold) => {

    const control = useAnimation()

    const { ref, inView } = useInView({
        threshold
    })

    useEffect(() => {
        if (inView) {
            control.start('in')
        }else {
            control.set('out')
        }
    }, [inView])


    return {
        ref, control
    }
}

export default useScrollAnimation

import {CFlex, CMargin, Flex, Margin, MBtn, RMargin} from "../../styles/common/CommonStyles";
import {useEffect, useRef, useState} from "react";
import {MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight} from "react-icons/md";
import {
    BannerWrapper,
    BannerImg,
    SlideBtn,
    DotContainer,
    Dot,
    BannerTitle, BannerDesc
} from "../../styles/components/BannerStyles"
import Text from "../../utils/Text";
import {useWindowSize} from "../../hooks/useWindowSize";
import {MOBILE_SIZE, TABLET_SIZE} from "../../utils/constants";


const Banner = () => {

    const {width} = useWindowSize()
    const interval = useRef(null)
    const [slideIndex, setSlideIndex] = useState(1)

    const slide = (type) => {
        const len = banners.length
        if (type === "next") {
            setSlideIndex(state => slideIndex === len ? 1 : state + 1)
        }else{
            setSlideIndex(state => slideIndex === 1 ? len : state - 1)
        }
    }

    useEffect(() => {
        if (banners.length > 1) {
            interval.current = setInterval(() => {
                slide("next")
            }, 4000)
        }

        return () => clearInterval(interval.current)
    }, [slideIndex])


    return (
        <div style={{ width: "100%", position: "relative" }}>
            {banners.map((banner, idx) => (
                <BannerWrapper active={idx + 1 === slideIndex}>
                    <BannerImg
                        src={width > TABLET_SIZE ? banner.img : width > MOBILE_SIZE ? banner.tImg : banner.mImg}
                    />
                    {banner.title ?
                        <CFlex justify="space-around" style={{ position: "absolute" }}>
                            <BannerTitle>
                                <Text tid={banner.title} />
                            </BannerTitle>
                            <BannerDesc>
                                <Text tid={banner.desc} />
                            </BannerDesc>
                            <CMargin margin="10px" />
                            <MBtn active>
                                <Text tid="register" />
                            </MBtn>
                        </CFlex>
                        : null
                    }
                </BannerWrapper>
            ))}
            {banners.length > 1 ?
                <>
                    <SlideBtn
                        whileHover={{scale: 1.1}}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => slide("prev")}
                        prev
                    >
                        <MdOutlineKeyboardArrowLeft size={24} color="#000" />
                    </SlideBtn>
                    <SlideBtn
                        whileHover={{scale: 1.1}}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => slide("next")}
                        next
                    >
                        <MdOutlineKeyboardArrowRight size={24} color="#000" />
                    </SlideBtn>

                    <DotContainer>
                        {Array.from({length: banners.length}).map((item, idx) => (
                            <Dot active={idx + 1 === slideIndex} onClick={() => setSlideIndex(idx+1)} />
                        ))}
                    </DotContainer>
                </>
                : null
            }
        </div>
    )
}

/**
 * change images here according to exchange
 * if you require one image the slider will be inactive
 * need 3 sizes of image (desktop - tablet - mobile )
 * you can add title and desc in image object , not required
 * @type {[{img: *, title: string, desc: string},{img: *, title: string, desc: string}]}
 */
const banners = [
    {
        img: require("../../assets/images/banner/banner01.jpg"),  // desktop img
        tImg: require("../../assets/images/banner/banner01.jpg"), // tablet img
        mImg: require("../../assets/images/banner/mbanner01.jpg"), // mobile img
        title: "",
        desc: ""
    }
]

export default Banner;
import styled, {css} from "styled-components";
import {motion} from "framer-motion";

const BannerWrapper = styled.div`
    width: 100%;
    height: 100%;
  display: inline-block;
    opacity: ${props => props.active ? 1 : 0};
    transition: opacity ease-in-out 0.5s;
    overflow: hidden;
  top: 0;
`

const BannerImg = styled.img`
      width: 100%;
      height: 100%;
`

const SlideBtn = styled(motion.div)`
  width: 32px;
  height: 32px;
  position: absolute;
  top: calc(50% - 16px);
  ${props => props.prev && css`
    left: 10px;
  `};
  ${props => props.next && css`
    right: 10px;
  `};
  background-color: #E5E9F0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #00000040;
  box-shadow: 0 0 4px #00000040;
  cursor: pointer;
  transition: all 0.1s;
  opacity: 0.2;
  
  &:hover{
    opacity: 1;
  }
`

const DotContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
`

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? props.theme.mainOrange : "#fff"};
  margin: 0 5px;
  cursor: pointer;
`

const BannerTitle = styled.div`
  font-size: 2rem;
  color: #fff;
  letter-spacing: 1.4px;
  margin: 10px 0;
  
  @media screen and (max-width: 1050px) {
    font-size: 1.5rem;
  };

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  };
  
`

const BannerDesc = styled.div`
  font-size: 1.5rem;
  color: #fff;
  letter-spacing: 1.4px;
  margin: 10px 0;
  
  @media screen and (max-width: 1050px) {
    font-size: 1.2rem;
  };

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  };

`


export {
    BannerWrapper,
    BannerImg,
    SlideBtn,
    DotContainer,
    Dot,
    BannerTitle,
    BannerDesc
}

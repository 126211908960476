import {useEffect, useRef, useState} from "react";
import {Background, Flex, Padding} from "../../styles/common/CommonStyles";
import styled from "styled-components";
import {SlideBtn} from "../../styles/components/BannerStyles";
import {MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight} from "react-icons/md";
import {useWindowSize} from "../../hooks/useWindowSize";
import {MOBILE_SIZE, TABLET_SIZE} from "../../utils/constants";
import {useMainContext} from "../../contexts/main";


const CardsSlider = () => {

    const {width} = useWindowSize()
    const [show, setShow] = useState(4)
    const interval = useRef(null)
    const {main: {lang}} = useMainContext()

    useEffect(() => {
        if (width > TABLET_SIZE) {
            setShow(4)
        }else if (width > MOBILE_SIZE) {
            setShow(2)
        }else {
            setShow(1)
        }
    }, [width])


    const [slides, setSlides] = useState([])
    const [current, setCurrent] = useState(0)

    const [transition, setTransition] = useState(null)
    const [transform, setTransform] = useState(null)

    useEffect(() => {
        const _slides = [...cards, ...cards]
        setSlides(_slides)
    }, [])


    const getTranslateVal = () => {
        const res = current * 100 / show
        return lang === "en" ?  res * -1 : res;
    };

    const slidePrev = () => {
        let _current = current - 1
        if (_current < 0) _current = cards.length
        setCurrent(_current)
    }

    const slideNext = () => {
        let _current = current + 1
        if (_current === cards.length * 2 - 3) _current = 0
        setCurrent(_current)
    }

    useEffect(() => {
        if (cards.length > 3) {
            interval.current = setInterval(() => {
                slidePrev()
            }, 4000)
        }

        return () => clearInterval(interval.current)
    }, [current])

    useEffect(() => {
        setTransition("transform 0.3s ease-in-out")
        setTransform(`translateX(${getTranslateVal()}%)`)
    }, [current])



    return (
        <Flex fw>
            <Background bg="primaryBg">
                <Padding padding="40px">
                    <Flex height="200px" fw style={{ overflow: "hidden" }}>
                        <Carousel transition={transition} transform={transform}>
                            {slides.map((slide, idx) => (
                                <Item width={`${100 / show}%`}>
                                    <img
                                        src={slide}
                                        alt=" "
                                        width="100%"
                                    />
                                </Item>
                            ))}
                        </Carousel>
                    </Flex>
                    <SlideBtn
                        whileHover={{scale: 1.1}}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => slidePrev()}
                        prev
                    >
                        <MdOutlineKeyboardArrowLeft size={24} color="#000" />
                    </SlideBtn>
                    <SlideBtn
                        whileHover={{scale: 1.1}}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => slideNext()}
                        next
                    >
                        <MdOutlineKeyboardArrowRight size={24} color="#000" />
                    </SlideBtn>
                </Padding>
            </Background>
        </Flex>
    )
}

const Carousel = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  transform: ${props => props.transform ? props.transform : "translateX(0)"};
  transition: ${props => props.transition && props.transition};
`

const Item = styled.li`
  width: ${props => props.width};
  flex-shrink: 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`


/**
 * add cards here - require at least 4 cards
 * @type {*[]}
 */
const cards = [
    require("../../assets/images/slide-cards/c1.jpg"),
    require("../../assets/images/slide-cards/c2.jpg"),
    require("../../assets/images/slide-cards/c3.jpg"),
    require("../../assets/images/slide-cards/c4.jpg")
]



export default CardsSlider;
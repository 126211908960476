import {Background, CFlex, CMargin, DText, Flex, Padding} from "../../styles/common/CommonStyles";
import {
    AMDesc,
    AMTitle,
    ScrollAnimationWrapper,
} from "../../styles/components/AnimatedMobileStyles";
import Text from "../../utils/Text";
import useScrollAnimation from "../../utils/useScrollAnimation";
import {useSocketContent} from "../../contexts/socket-content";
import styled from "styled-components";
import {motion} from "framer-motion";
import {HOME, MOBILE_SIZE, SOCKET_URL} from "../../utils/constants";
import {formatNumber} from "../../utils/common";
import {useWindowSize} from "../../hooks/useWindowSize";
import { BiRightArrowAlt } from 'react-icons/bi'


const CryptoPriceTable = () => {

    const {prices} = useSocketContent()
    const { width } = useWindowSize()

    const { ref: titleRef, control: titleControl } = useScrollAnimation(0.2)
    const { ref: descRef, control: descControl } = useScrollAnimation(0.2)
    const { ref: tableRef, control: tableControl } = useScrollAnimation(0.5)

    const navigateToTrade = (coin) => {
        window.location.href = HOME + `trade?id=${coin.coin}`
    }

    const navigateToMarkets = () => {
        window.location.href = HOME + "trade/markets"
    }

    return (
        <>
            <Background bg='primaryBg' style={{ background: '#061121' }}>
                <Padding padding='100px 0'>
                    <CFlex fw>
                        <ScrollAnimationWrapper fw height='60px' ref={titleRef}>
                            <AMTitle
                                variants={variants}
                                initial='out'
                                animate={titleControl}
                            >
                                <Text tid='crypto-table-title' />
                            </AMTitle>
                        </ScrollAnimationWrapper>

                        <ScrollAnimationWrapper fw height='60px' ref={descRef}>
                            <AMDesc
                                variants={variants}
                                initial='out'
                                animate={descControl}
                            >
                                <Text tid='crypto-table-desc' />
                            </AMDesc>
                        </ScrollAnimationWrapper>

                        <CMargin margin='20px' />

                        <ScrollAnimationWrapper fw ref={tableRef}>
                            <AnimateTable
                                variants={tableVariants}
                                initial='out'
                                animate={tableControl}
                            >
                                {prices?.slice(0, 5).map((price, idx) => {
                                    const change = price.changes['24h']?.changePresent

                                    return (
                                        width > MOBILE_SIZE ?
                                            <CryptoRow>
                                                <CryptoColumn width='15%'>
                                                    <DText fontSize='b' primary>
                                                        {price.name}
                                                    </DText>
                                                    <DText fontSize='m' main style={{margin: '0 2px'}}>
                                                        {price.id?.toUpperCase()}
                                                    </DText>
                                                </CryptoColumn>
                                                <CryptoColumn width='30%'>
                                                    <DText fontSize='sb' primary>
                                                        ${formatNumber(price.value, 6)}
                                                    </DText>
                                                </CryptoColumn>
                                                <CryptoColumn width='10%'>
                                                    <DText fontSize='m' color={change > 0 ? '#1ce087' : '#e9106c'}>
                                                        {change}%
                                                    </DText>
                                                </CryptoColumn>
                                                <CryptoColumn width='20%'>
                                                    <DText fontSize='b' primary>
                                                        {formatNumber(price.buy)} IRT
                                                    </DText>
                                                </CryptoColumn>
                                                <CryptoColumn width='20%'>
                                                    <img src={SOCKET_URL + `graphs/${price.coin}.svg`}
                                                         alt=" "
                                                    />
                                                </CryptoColumn>
                                                <CryptoColumn justify='flex-end'>
                                                    <CryptoTradeBtn onClick={() => navigateToTrade(price)}>
                                                        <Flex fw fh>
                                                            Trade
                                                        </Flex>
                                                    </CryptoTradeBtn>
                                                </CryptoColumn>
                                            </CryptoRow>
                                            :
                                            <RCryptoRow>
                                                <CFlex fw align='flex-start'>
                                                    <DText fontSize='b' primary>
                                                        {price.name}
                                                    </DText>
                                                    <DText fontSize='m' main style={{margin: '0 2px'}}>
                                                        {price.id?.toUpperCase()}
                                                    </DText>
                                                </CFlex>
                                                <Flex>
                                                    <CFlex align='flex-end'>
                                                        <DText fontSize='sb' primary>
                                                            ${formatNumber(price.value, 6)}
                                                        </DText>
                                                        <DText fontSize='m' color={change > 0 ? '#1ce087' : '#e9106c'}>
                                                            {change}%
                                                        </DText>
                                                    </CFlex>
                                                    <CryptoTradeBtn onClick={() => navigateToTrade(price)}>
                                                        <Flex fw fh>
                                                            Trade
                                                        </Flex>
                                                    </CryptoTradeBtn>
                                                </Flex>
                                            </RCryptoRow>
                                    )
                                })}
                            </AnimateTable>
                        </ScrollAnimationWrapper>
                        <CMargin margin='20px' />
                        <ViewMore onClick={navigateToMarkets}>
                            <Flex fw fh>
                                <DText fontSize='b' primary>
                                    View more
                                </DText>
                                <MoreArrow size={24} />
                            </Flex>
                        </ViewMore>
                    </CFlex>
                </Padding>
            </Background>
        </>
    )
}


const AnimateTable = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: ltr;
  font-family: 'Vazir';
`

const CryptoTradeBtn = styled.div`
  background-color: #f4f4f4;
  color: #061121;
  height: 42px;
  width: 90px;
  font-weight: 600;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  
  &:hover {
    border: 1px solid #1199f980;
    background-color: transparent;
    color: #f4f4f4;
  }
  
  @media screen and (max-width: 768px) {
    height: 36px;
    width: 64px;
    margin-left: 5px;
  }
`


const CryptoRow = styled(Flex)`
  min-width: 900px;
  width: 60%;
  justify-content: space-evenly;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.color}40;
  
  @media screen and (max-width: 900px) {
    min-width: 700px;
  }
`

const CryptoColumn = styled(Flex)`
  justify-content: ${props => props.justify || 'flex-start'};
  width: ${props => props.width};
  overflow: hidden;
  padding: 0 5px;
`


const RCryptoRow = styled(Flex)`
  width: 90%;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid ${props => props.theme.color}40;
`


const ViewMore = styled.div`
  border-radius: 4px;
  height: 48px;
  padding: 0 20px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.primary};
  
  &:hover {
    background-color: ${props => props.theme.tInputBg};
  }
`

const MoreArrow = styled(BiRightArrowAlt)`
  color: ${props => props.theme.primary};
  margin: 0 5px;
`


const variants = {
    in: {
        y: 0, transition: {duration: 0.2, ease: 'easeOut'}
    },
    out: {
        y: 100
    }
}


const tableVariants = {
    in: {
        y: 0, opacity: 1, transition: {duration: 0.3, ease: 'easeOut'}
    },
    out: {
        y: 60, opacity: 0
    }
}

export default CryptoPriceTable
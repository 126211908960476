import {useEffect, useRef} from "react";
import {useMainContext} from "../../contexts/main";


const TradingWidget = ({type = "tape"} = {}) => {
    const ref = useRef()
    const { main: { theme } } = useMainContext()

    const getInnerObject = () => {
        return theme === "dark" ? `{
            "symbols": [
                 {
                  "description": "",
                  "proName": "KUCOIN:BTCUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:TRXUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:ETHUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:DOGEUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:SHIBUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:ADAUSDT"
                }
            ],
            "showSymbolLogo": true,
            "colorTheme": "dark",
            "isTransparent": true,
            "displayMode": "adaptive",
            "locale": "en"
        }` :
            `{
            "symbols": [
                 {
                  "description": "",
                  "proName": "KUCOIN:BTCUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:TRXUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:ETHUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:DOGEUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:SHIBUSDT"
                },
                {
                  "description": "",
                  "proName": "KUCOIN:ADAUSDT"
                }
            ],
            "showSymbolLogo": true,
            "colorTheme": "light",
            "isTransparent": true,
            "displayMode": "adaptive",
            "locale": "en"
        }`
    }


    useEffect(() => {
        for (let i = 0; i < ref.current?.children.length; i++) {
            ref.current?.removeChild(ref.current?.children[i])
        }
    }, [theme])

    useEffect(() => {
        // console.log("CreatingScript", theme, ref.current?.children)
        const script = document.createElement('script')
        script.src =
            type === 'tape'
                ? 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
                : 'https://s3.tradingview.com/external-embedding/embed-widget-tickers.js'
        script.async = true
        script.innerHTML = getInnerObject()

        ref.current?.appendChild(script)

    }, [theme])


    return (
        <>
            <div className="tradingview-widget-container" >
                <div className="tradingview-widget-container__widget" ref={ref}/>
            </div>
        </>
    )
}

export default TradingWidget;
import i18next from "i18next";
import { useContext, createContext, useState, useEffect } from "react";
import { loadPersistedData, persistData } from "../utils/persistor";
import cookieService from "../services/cookie"

const context = createContext({
    main: {
        theme: "dark", setTheme: () => { },
        lang: "fa", setLang: () => { }
    },
    profile: {
        token: null, setToken: () => { }
    }
})

export const useMainContext = () => {
    return useContext(context)
}

const MainContextProvider = ({ children }) => {
    const [theme, setTheme] = useState(loadPersistedData('theme') ? loadPersistedData('theme') : "dark")
    const [lang, setLang] = useState(loadPersistedData('lang') ? loadPersistedData('lang') : "fa")
    const [token, _setToken] = useState(cookieService.get('token', {path: '/'}))

    useEffect(() => {
        persistData('theme', theme)
    }, [theme])

    useEffect(() => {
        i18next.changeLanguage(lang)
        persistData('lang', lang)
    }, [lang])


    const setToken = (token) => {
        cookieService.set('token', token, { path: '/' })
        _setToken(token)
    }

    return (
        <context.Provider value={{
            main: {
                theme, setTheme, lang, setLang
            },
            profile: {
                token, setToken
            }
        }}>
            {children}
        </context.Provider>
    )
}

export default MainContextProvider;

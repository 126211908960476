import styled from "styled-components";

export const SubPageHead = styled.h3``
export const SubPageDescription = styled.p`
	//font-size: 0.5rem;
`

export const ContentHeadLine = styled.h3`
	color: ${(props) => props.theme.clientMain};
	white-space: break-spaces;
`

export const ContentBody = styled.p`
	line-height: 26px;
	white-space: break-spaces;

	span {
		//padding: 5px;
	}
`
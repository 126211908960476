import { AnimatePresence, motion } from "framer-motion";
import React, { useRef } from "react";
import styled from "styled-components";
import { variants } from "../../utils/common"
import useClickOutside from "../../hooks/useClickOutside";


const ModalLayout = ({ width, minWidth, children, onClose, open }) => {

    const modalRef = useRef()
    useClickOutside(modalRef, onClose)

    if (!open) {
        return <></>
    }

    return (
        <Body>
            <AnimatePresence exitBeforeEnter>
                {open &&
                    <Wrapper ref={modalRef} variants={variants} initial="out" animate="in" exit="out" width={width} minWidth={minWidth}>
                        {children}
                    </Wrapper>
                }
            </AnimatePresence>
        </Body>
    )
}

const Body = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1001;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled(motion.div)`
    width: ${props => props.width || "50%"};
    min-width: ${(minWidth) => minWidth};
    max-width: 800px;
    padding: 35px 20px;
    background-color: ${props => props.theme.primaryBg};
    border-radius: 8px;
    box-shadow: 0 0 10px black;
`

export default ModalLayout;
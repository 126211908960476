import SubPageLayout from "../components/layouts/SubPageLayout";
import {ContentBody, ContentHeadLine} from "../styles/layout/SubpagesSyles";
import {useTranslation} from "react-i18next";


const Subpage = () => {
    const { t } = useTranslation()
    const content = [
        { type: 'body', value: 'about-section-1-body' },
        { type: 'headline', value: 'about-section-2-head' },
        { type: 'body', value: 'about-section-2-body' },
        { type: 'headline', value: 'about-section-3-head' },
        { type: 'body', value: 'about-section-3-body' },
    ]

    return(
        <SubPageLayout header="about-us">
            {content.map((item, index) => {
                const { type, value } = item
                const Component = type === 'headline' ? ContentHeadLine : ContentBody
                return (
                    <Component key={index}>
                        {t(value)}
                    </Component>
                )
            })}
        </SubPageLayout>
    )
}


export default Subpage
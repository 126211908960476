import {ThemeProvider} from "styled-components";
import {getMainTheme} from "../../utils/theme";
import {useMainContext} from "../../contexts/main";
import {TABLET_SIZE} from "../../utils/constants";
import MasterHeader from "./MasterHeader";
import {MainBody} from "../../styles/layout/MainStyles";
import {useWindowSize} from "../../hooks/useWindowSize";
import BottomBar from "./BottomBar";
import Toast from "../modals/Toast";
import MasterFooter from "./MasterFooter";


const MainLayout = ({children}) => {
    const {width} = useWindowSize()
    const {
        main: { theme, lang },
    } = useMainContext()

    return(
        <ThemeProvider theme={getMainTheme(theme, lang)}>
            <MasterHeader />
            <MainBody>
                {children}
            </MainBody>
            <MasterFooter hasQr/>
            <Toast />
            { width < TABLET_SIZE &&
                <BottomBar />
            }
        </ThemeProvider>
    )
}

export default MainLayout;
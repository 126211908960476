import {
    Background,
    CFlex,
    CMargin,
    DLink,
    DText,
    Flex,
    Grid,
    MBtn,
    Padding,
    RMargin,
    QrWrapper
} from "../../styles/common/CommonStyles";
import Text from "../../utils/Text";
import {FooterWrapper, LineBreak} from "../../styles/layout/MainStyles";
import QRCode from "react-qr-code";
import {HOME, MOBILE_SIZE} from "../../utils/constants";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useNavigate} from "react-router-dom";
import {FaFacebook, FaTelegramPlane} from "react-icons/fa"
import {IoLogoTwitter} from "react-icons/io"


const MasterFooter = ({hasQr}) => {

    const {width} = useWindowSize()
    const navigate = useNavigate()

    const goToSignin = (type) => {
        window.location.href = HOME + `user/register-signin?id=${type}`
    }

    return (
        <FooterWrapper>
            <CFlex fw fh>
                {/* first section */}
                <Flex fw>
                    <Background bg="primaryBg">
                        <Padding padding="40px 0">
                            <CFlex fw>
                                <DText fontSize="sb" primary>
                                    <Text tid="trade-now" />
                                </DText>
                                <CMargin margin="15px" />
                                <Flex>
                                    <MBtn onClick={() => goToSignin("register")} active>
                                        <Text tid="register" />
                                    </MBtn>
                                    <RMargin margin="10px" />
                                    <MBtn onClick={() => goToSignin("signin")}>
                                        <Text tid="sign-in" />
                                    </MBtn>
                                </Flex>
                            </CFlex>
                        </Padding>
                    </Background>
                </Flex>

                {/* second section */}
                <Flex justify="space-around" fw>
                    <Background bg="mainBg">
                        <Padding padding="20px">
                            <Grid gc={4} gcR={2} width="80%">
                                {links.map(section => (
                                    <CFlex align="flex-start" justify="flex-start">
                                        <CMargin margin="5px" />
                                        <DText fontSize="b" primary><Text tid={section.header} /></DText>
                                        <CMargin margin="5px" />
                                        {section.links.map(link => (
                                            <>
                                                <CMargin margin="5px" />
                                                <DLink fontSize="m" main onClick={() => navigate(link.path)}>
                                                    <Text tid={link.link} />
                                                </DLink>
                                            </>
                                        ))}
                                    </CFlex>
                                ))}
                            </Grid>
                            {hasQr ?
                                <Flex fh>
                                    <QrWrapper>
                                        <QRCode size={width > MOBILE_SIZE ? 100 : 80} value={HOME} />
                                    </QrWrapper>
                                </Flex>
                                : null
                            }
                        </Padding>
                    </Background>
                </Flex>

                <CFlex fw>
                    <Background bg="mainBg">
                        <Padding padding="20px 40px">
                            <LineBreak />
                            <Flex justify="space-between" fw style={{ flexDirection: width > MOBILE_SIZE ? "row" : "column" }}>
                                <Flex>
                                    {community.map(com => (
                                        <>
                                            <RMargin margin="10px" />
                                            <DText cFontSize="24px" style={{cursor: "pointer"}} main>
                                                {com}
                                            </DText>
                                        </>
                                    ))}
                                </Flex>
                                <DText fontSize="m" main>
                                    {/*<Text tid="copyright" />*/}
                                </DText>
                            </Flex>
                        </Padding>
                    </Background>
                </CFlex>
            </CFlex>
        </FooterWrapper>
    )
}


/**
 * footer links
 * structure => { header, links[ { link, path } ] }
 */
const links = [
    {
        header: "about-us",
        links: [
            {link: "about-us", path: "#"},
            {link: "contact-us", path: "#"},
            {link: "rules", path: "#"},
            {link: "news", path: "#"}]
    },

    {
        header: "products",
        links: [
            { link: "platform", path: "#"},
            { link: "academy", path: "#"},
            { link: "r&d", path: "#"},
            { link: "mobile-application", path: "#"}]
    },
    {
        header: "services",
        links: [
            { link: "p2p-service", path: "#"},
            { link: "otc-service", path: "#"},
            { link: "downloads", path: "#"},
            { link: "fees", path: "#"}]
    },
    {
        header: "support",
        links: [
            { link: "help-center", path: "#"},
            { link: "faq", path: "#"}]
    }
]

/**
 * community icons goes here
 */
const community = [
    <FaTelegramPlane size={24}/>, <FaFacebook size={24}/>, <IoLogoTwitter size={24}/>
]

export default MasterFooter;
import {formatNumber, stringToNumber} from "./common";

/**
 * @param {Object} type - buy / sell
 * @param {string} tradeType - p2p / otc
 * @param {Object} wages - wages
 * @param data - main data - p2pData / otcData
 * @param socketContent - socket - buy and sell orders uses here
 */
const getCalculatedWages = (type, tradeType, wages, data, socketContent  ) => {


    let operationType;
    let affPair = 0;
    let affValue = 0
    if (tradeType === "p2p") {
        if (type === "buy") {
            operationType = stringToNumber(data.price) < Math.min(...socketContent?.sellOrders?.map(o => o.priceUnit)) ? "maker" : "taker"
        }else {
            operationType = stringToNumber(data.price) > Math.max(...socketContent?.buyOrders?.map(o => o.priceUnit)) ? "maker" : "taker"
        }
    }else {
        operationType = "maker" // TODO : Should change to taker in api and code
    }
    const totalPair = stringToNumber(data.price) * stringToNumber(data.amount)
    const feePair = wages.wage[operationType].factor * totalPair / 100
    const feeValue = wages.wage[operationType].factor * data.amount / 100
    const feeOffPair = wages.off * feePair / 100
    const feeOffValue = wages.off * feeValue / 100
    if (wages.affiliate.calculated) {
        affPair = wages.affiliate.calculated * stringToNumber(data.price)
        affValue = wages.affiliate.calculated
    }
    const receiveValue = stringToNumber(data.amount) - feeValue + feeOffValue + affValue
    const receivePair = totalPair - feePair + feeOffPair + affPair

    return {
        totalPair: formatNumber(totalPair), totalValue: data.amount,
        feePair: formatNumber(feePair), feeValue: formatNumber(feeValue),
        feeOffPair: formatNumber(feeOffPair), feeOffValue: formatNumber(feeOffValue),
        affPair: formatNumber(affPair), affValue: formatNumber(affValue),
        receivePair: formatNumber(receivePair), receiveValue: formatNumber(receiveValue),
        loading: false
    }
}


const getExtraWages = (
    networks,
    data,
    type
) => {

    const wages = []
    const nets = networks[type === "buy" ? "withdraw" : "deposit"]
    nets?.forEach(net => {
        const total = parseFloat(stringToNumber(data.total))
        const price = parseFloat(stringToNumber(data.price))

        let fee = total * net.feeFactor / 100
        let feeValue = fee / price

        if (net.feeMax && feeValue > net.feeMax) {
            feeValue = net.feeMax
            fee = feeValue * price
        }

        let payload = {
            network: net.network,
            fee, feeValue
        }

        // TODO: we should consider normal fees too
        const receive = total - fee
        if (receive > net.max && type === "sell") {
            payload = {
                ...payload,
                showNote: true,
                receiveMax: net.max
            }
        }

        wages.push(payload)
    })

    return wages
}



export {
    getCalculatedWages,
    getExtraWages
}
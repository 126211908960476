import styled, {css} from "styled-components";

const fontSizes = {
    sb: { d: "2rem", t: "1.5rem", m: "1rem" },
    b: { d: "1.2rem", t: "1rem", m: "0.9rem"},
    m: {d: "0.9rem", t: "0.8rem", m: "0.7rem"},
    s: {d: "0.7rem", t: "0.6rem", m: "0.55rem"}
}

const Flex = styled.div`
  display: flex;
  width: ${props => props.width ? props.width : props.fw ? "100%" : "unset"};
  height: ${props => props.height ? props.height : props.fh ? "100%" : "unset"};
  justify-content: ${props => props.justify || "center"};
  align-items: ${props => props.align || "center"};
  flex-wrap: ${props => props.wrap && "wrap"};
  position: relative;
`

const CFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width ? props.width : props.fw ? "100%" : "unset"};
  height: ${props => props.height ? props.height : props.fh ? "100%" : "unset"};
  justify-content: ${props => props.justify || "center"};
  align-items: ${props => props.align || "center"};
  flex-wrap: ${props => props.wrap && "wrap"};
  position: relative;
`

const Grid = styled.div`
  display: grid;
  width: ${props => props.width ? props.width : props.fw ? "100%" : "unset"};
  height: ${props => props.height ? props.height : props.fh ? "100%" : "unset"};
  grid-template-rows: ${props => props.gr || "unset"};
  grid-template-columns: ${props => `repeat(${props.gc}, 1fr)`};
  grid-column-gap: ${props => props.cgap && props.cgap};
  
  @media screen and (max-width: 1050px) {
    grid-template-columns: ${props => `repeat(${props.gc / 2}, 1fr)`};
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: ${props => props.gcR ? `repeat(${props.gcR}, 1fr)` : `repeat(${props.gc / 4 > 1 ? (props.gc / 4) : 1}, 1fr)`};
  }
  
  //@media screen and (max-width: 480px) {
  //  grid-template-columns: repeat(1, 1fr);
  //}
`

const Background = styled.div`
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  flex-wrap: inherit;
  align-items: inherit;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme[props.bg]};
`

const Padding = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: inherit;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  position: inherit;
  flex-wrap: inherit;
  padding: ${props => props.padding};
`

const RMargin = styled.div`
  width: inherit;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  position: inherit;
  margin: 0 ${props => props.margin};
`

const CMargin = styled.div`
  height: inherit;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  position: inherit;
  margin: ${props => props.margin} 0;
`

const Margin = styled.div`
  width: inherit;
  height: inherit;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  position: inherit;
  margin: ${props => props.margin};
`

const DText = styled.div`
  font-size: ${props => props.cFontSize ? props.cFontSize : fontSizes[props.fontSize].d};
  color: ${props => props.color && props.color};
  ${props => props.main && css`
    color: ${props => props.theme.color};
  `};
  ${props => props.primary && css`
    color: ${props => props.theme.primary};
  `};
  direction: ${props => props._type === "number" && "ltr"};
  font-family: ${props => props.type === "number" && "monospace"};
  
  @media screen and (max-width: 1050px){
    font-size: ${props => props.cFontSize ? props.cFontSize : fontSizes[props.fontSize].t};
  }
  
  @media screen and (max-width: 768px) {
    font-size: ${props => props.cFontSize ? props.cFontSize : fontSizes[props.fontSize].m};
  }
`

const DLink = styled(DText)`
  cursor: pointer;
  &:hover{
    color: ${props => props.theme.clientMain};
  }
`


const MBtn = styled.div`
  min-width: 120px;
  min-height: 38px;
  width: ${props => props.width && props.width};
  height: ${props => props.height && props.height};
  background-color: ${props => props.active ? props.theme.clientMain : props.theme.inActive};
  border-radius: 6px;
  box-shadow: 0 0 4px ${props => props.theme.mainBg};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 1.2px;
  color: ${props => props.active ? "#000" : props.theme.color};
  font-size: 0.9rem;
  cursor: pointer;
  
  &:hover{
    background-color: transparent;
    color:  ${props => props.theme.color};
    border: 2px solid ${props => props.active ? props.theme.clientMain : props.theme.color};
  }
`

const QrWrapper = styled.div`
  border-radius: 8px;
  background-color: ${props => props.theme.primary};
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Column = styled.div`
  display: flex;
  justify-content: ${props => props.justify || "flex-start"};
  align-items: center;
  width: ${props => props.width && props.width};
  line-height: 2;
  padding: 0 5px;

  @media screen and (max-width: 1050px){
    font-size: 0.8rem;
  }
  @media screen and (max-width: 768px){
    font-size: 0.7rem;
  }
`

const Row = styled.div`
  display: flex;
  width: ${props => props.width ? props.width : "100%"};
  padding: 20px;
  cursor: pointer;
  border-radius: 8px;
  
  &:hover{
    background-color: ${props => !props.header && props.theme.inActive};
  }
  
  @media screen and (max-width: 768px){
    padding: 20px 0;
  }

  @media screen and (max-width: 480px){
    flex-direction: column;
  }
`


const Tab = styled.div`
    cursor: pointer;
    min-width: 80px;
    border-bottom: 1px solid ${props => props.active ? props.theme.clientMain : props.theme.color};
    border-radius: 4px;
    ${props => props.theme.english && css`
      border-right: 1px solid ${props => props.active ? props.theme.clientMain : props.theme.color};
    `};
    ${props => !props.theme.english && css`
      border-left: 1px solid ${props => props.active ? props.theme.clientMain : props.theme.color};
    `};
    padding: 8px 16px;
    color: ${props => props.active ? props.theme.clientMain : props.theme.color};
    font-size: 1rem;

  @media screen and (max-width: 1050px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`

const Absolute = styled.div`
  position: absolute;
  left: ${props => props.left};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  height: ${props => props.height || "100%"};
  width: ${props => props.width || "100%"};
`

const SubCard = styled.div`
  border-radius: 22px;
  padding: 40px 20px;
  background-color: ${(props) => props.theme.primaryBg};
  margin-top: ${(props) => props.top};
  border: 1px solid #00000015;
  box-shadow: 0 0 2px #00000025;
  position: relative;
  z-index: 10;
  min-height: 200px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;

  margin-left: auto;
  margin-right: auto;

  & > div {
    //max-width: 720px;
    width: 100%;
  }

  @media screen and (max-width: 1050px) {
    //margin-top: 200px;
    padding: 20px;
    border-radius: 12px;

    ${props => props.noCard && css`
			padding: 20px 8px;
		`};
  }
`

const FeeRow = styled(Row)`
  background-color: ${props => props.idx % 2 ? props.theme.mainBg : props.theme.primaryBg};
  border: 1px solid ${props => props.theme.color}15;

  @media screen and (max-width: 1050px){
    width: 80%;
  }
  @media screen and (max-width: 768px){
    width: 100%;
    flex-direction: row;
    min-width: 400px;
    margin: 0 auto;
  }
`

const SpinnerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.primaryBg};
`

const Block = styled.div`
  display: block;
  text-align: center;
  width: 100%;
`

export {
    DText,
    Flex,
    CFlex,
    Background,
    Padding,
    MBtn,
    Margin,
    CMargin,
    RMargin,
    DLink,
    Grid,
    QrWrapper,
    Column,
    Row,
    Tab,
    Absolute,
    SubCard,
    FeeRow,
    SpinnerContainer,
    Block
}
import {Card, CardContentBox, CardGrid} from "../../styles/components/CardStyles";
import {Background, DText, Flex, Padding} from "../../styles/common/CommonStyles";
import Text from "../../utils/Text";


const Cards = () => {

    return (
        <Flex fw>
            <Background bg="primaryBg">
                <Padding padding="40px">
                    <CardGrid>
                        {data.map(card => (
                            <Card>
                                <img
                                    width={imageSize}
                                    height={imageSize}
                                    src={require(`../../assets/images/card-icons/${card.image}.png`)} alt=" "
                                />
                                <DText
                                    fontSize="b"
                                    style={{margin: "10px 0"}}
                                    primary
                                >
                                    <Text tid={card.header} />
                                </DText>
                                {typeof card.content === "string" ?
                                    <DText fontSize="m">
                                        <Text tid={card.content} />
                                    </DText>
                                    :
                                    <CardContentBox>
                                        {card.content.map(text => (
                                            <DText fontSize="m">
                                                <Text tid={text} />
                                            </DText>
                                        ))}
                                    </CardContentBox>
                                }
                            </Card>
                        ))}
                    </CardGrid>
                </Padding>
            </Background>
        </Flex>
    )
}


/**
 * add content here
 * image (image name - string) .. header (string) .. content (can be []string || string)
 */
const imageSize = 72
const data = [
    {
        image: "1", header: "wallet-manage-header", content: "wallet-manage-desc"
    },
    {
        image: "2", header: "bank-card-header", content: "bank-card-desc"
    },
    {
        image: "3", header: "secure-wallet-header", content: "secure-wallet-desc"
    },
    {
        image: "4", header: "fee-header", content: "fee-desc"
    },
]


export default Cards;
import styled from 'styled-components'
import { FaWallet, FaExchangeAlt, FaChartLine, FaColumns } from 'react-icons/fa'
import Text from '../../utils/Text'
import { useEffect, useState } from 'react'
import { HOME } from '../../utils/constants'
import {
	DText,
	TabItem,
	Body,
	BottomBarWrapper
} from "../../styles/layout/BottomBarStyles"


const BottomBar = () => {
	const [active, setActive] = useState(-1)

	useEffect(() => {
		const pathname = window.location.pathname
		if (pathname.includes('wallets')) {
			setActive(3)
		} else if (pathname.includes('user')) {
			setActive(0)
		}
	}, [])

	const onTabClicked = (idx) => {
		switch (idx) {
			case 0:
				window.location.href = HOME + "user/"
				break
			case 1:
				window.location.href = HOME + "trade/"
				break
			case 2:
				window.location.href = HOME + "trade/otc"
				break
			default:
				window.location.href = HOME + "user/wallets"
		}
	}

	return (
		<Body>
			<BottomBarWrapper>
				{tabs.map((tab, idx) => (
					<TabItem onClick={() => onTabClicked(idx)}>
						<tab.icon size={18} active={idx === active} />
						<DText color={active === idx && '#ffc800'}>
							<Text tid={tab.name} />
						</DText>
					</TabItem>
				))}
			</BottomBarWrapper>
		</Body>
	)
}

const DashboardIcon = styled(FaColumns)`
	color: ${(props) => (props.active ? props.theme.mainOrange : props.theme.color)};
`
const MarketsIcon = styled(FaChartLine)`
	color: ${(props) => (props.active ? props.theme.mainOrange : props.theme.color)};
`
const OtcIcon = styled(FaExchangeAlt)`
	color: ${(props) => (props.active ? props.theme.mainOrange : props.theme.color)};
`
const WalletIcon = styled(FaWallet)`
	color: ${(props) => (props.active ? props.theme.mainOrange : props.theme.color)};
`

const tabs = [
	{ name: 'dashboard', icon: DashboardIcon },
	{ name: 'markets', icon: MarketsIcon },
	{ name: 'otc', icon: OtcIcon },
	{ name: 'wallet', icon: WalletIcon },
]

export default BottomBar

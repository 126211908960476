import {useQueryContext} from "./contexts/query";
import {QueryClientProvider} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import MainContextProvider from "./contexts/main";
import Index from "./pages";
import './i18n'


function App() {

  const {queryClient} = useQueryContext()

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <MainContextProvider>
          <Index />
      </MainContextProvider>
    </QueryClientProvider>
  );
}

export default App;

import {Absolute, Background, CFlex, DText, Flex, Grid, Padding} from "../../styles/common/CommonStyles";
import Text from "../../utils/Text";
import {MOBILE_SIZE, TABLET_SIZE} from "../../utils/constants";
import {useWindowSize} from "../../hooks/useWindowSize";


const MobileMoc = () => {
    const {width} = useWindowSize()
    return (
        <Flex fw>
            <Background bg="mainBg">
                <Padding padding="40px">
                    <Grid gc={2} width="80%">
                        <Flex fw>
                            <img src={require("../../assets/images/platforms/mobilemoc.png")} alt="" width="100%" height="100%" />
                        </Flex>
                        <CFlex fw>
                            <Padding padding="40px 0">
                                <DText fontSize="sb" primary>
                                    <Text tid="trade-everywhere" />
                                </DText>
                                <DText fontSize="b">
                                    <Text tid="moc-download-app" />
                                </DText>
                            </Padding>
                        </CFlex>
                    </Grid>
                </Padding>
            </Background>
        </Flex>
    )

}

export default MobileMoc;
import i18next from "i18next";
import {useEffect, Suspense} from "react";
import {useMainContext} from "../contexts/main";
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Landing from "./Landing"
import GlobalLoading from "../components/layouts/GlobalLoading";
import {SocketProvider} from "../contexts/socket";
import {SocketContentProvider} from "../contexts/socket-content";
import Subpage from "./Subpage";


const Index = () => {

    const {main: {lang}} = useMainContext()

    useEffect(() => {
        i18next.changeLanguage(lang)
        //eslint-disable-next-line
    }, [])

    return (
        <Router>
            <Suspense fallback={<GlobalLoading />}>
                <Routes>
                    <Route index path='/' element={
                        <SocketProvider>
                            <SocketContentProvider>
                                <Landing />
                            </SocketContentProvider>
                        </SocketProvider>
                    } />

                    {/* subpages go here */}
                    <Route path='/about' element={<Subpage />} />

                    <Route path='*' element={<Navigate to='/' replace />} />
                </Routes>
            </Suspense>
        </Router>
    )
}


export default Index;
import styled, {css} from "styled-components";
import {CFlex} from "../common/CommonStyles";

const Label = styled.h3`
  width: ${props => props.width || "100%"};
  align-self: center;
  color: ${props => props.theme.color};
  text-align: start;
  font-size: ${props => props.fontSize || "13px"};
  font-weight: 600;
  padding: 0 2px;
  letter-spacing: 1.2px;
  position: relative;

  @media only screen and (max-width: 1050px){
    font-size: 0.75rem;
  }
`


const StyledNumberFormat = styled.input`
    outline: none;
    width: ${props => props.width || "100%"};
    padding: 0 20px;
    top: 0;
    left: 0;
    align-self: center;
    height: 48px;
    color: ${props => props.theme.color};
    background-color: ${props => props.theme.otcInput};
    border-radius: 4px;
    border: none;
    ${props => props.theme.english && css`
        font-family: monospace;
        font-size: 13px;
    `}
    
    &:focus{
        border: 0.5px solid #FCD535;
        background-color: ${props => props.background};
    }

  
  
`

const OtcBtn = styled.div`
  cursor: pointer;
  min-width: 180px;
  min-height: 42px;
  border-radius: 6px;
  background-color: ${props => props.active && props.theme.clientMain};
  color: ${props => props.active ? props.theme.mainBg : props.theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  
  margin: 0 10px;
  
  ${props => props.tab && css`
    background-color: #5e7ddb;
  `};

  
  @media screen and (max-width: 768px) {
    margin: 10px;
  } 
`

const ProOrderWrapper = styled(CFlex)`
  width: 40%;
  max-width: 560px;
  
  @media screen and (max-width: 1050px) {
    width: 60%;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
  }
`

export {
    StyledNumberFormat,
    Label,
    OtcBtn,
    ProOrderWrapper
}
import {
    CFlex,
    CMargin,
    Column,
    DText,
    Flex,
    MiniChartWrapper,
    Padding,
    RMargin,
    Row,
    Tab
} from "../../styles/common/CommonStyles";
import Text from "../../utils/Text";
import {useSocketContent} from "../../contexts/socket-content";
import {HOME, MOBILE_SIZE, SOCKET_URL} from "../../utils/constants";
import {useMainContext} from "../../contexts/main";
import {formatNumber} from "../../utils/common";
import {useState} from "react";
import {useWindowSize} from "../../hooks/useWindowSize";


const PriceTable = () => {

    const {prices} = useSocketContent()
    const {width} = useWindowSize()
    const { main: {lang} } = useMainContext()
    const [ currency, setCurrency ] = useState("IRT")

    const navigateToTrade = (coin) => {
        window.location.href = HOME + `trade?id=${coin.coin}`
    }

    const navigateToMarkets = () => {
        window.location.href = HOME + "trade/markets"
    }

    return (
        <>
            <Flex justify="flex-start">
                <Padding padding="40px 40px 0 40px">
                    {["IRT", "USDT"].map(curr => (
                        <Tab active={curr === currency} onClick={() => setCurrency(curr)}>
                            <Flex fw fh>
                                {curr}
                            </Flex>
                        </Tab>
                    ))}
                </Padding>
            </Flex>
            <CFlex fw>
                <Padding padding={width > MOBILE_SIZE ? "0 40px" : "0 20px"}>
                    {width > MOBILE_SIZE ?
                        <Row header>
                            {headers.map((header, idx) => (
                                <Column width="25%" justify={(idx === 2 || idx === 3) && "center"} >
                                    <DText fontSize="m" main>
                                        <Text tid={header} />
                                    </DText>
                                </Column>
                            ))}
                        </Row>
                        : null
                    }
                    {prices?.slice(0, 5).map(item => (
                        width > MOBILE_SIZE ?
                            <DesktopTable
                                item={item}
                                currency={currency}
                                navigateToTrade={navigateToTrade}
                            />
                            :
                            <MobileTable
                                item={item}
                                currency={currency}
                                navigateToTrade={navigateToTrade}
                            />
                    ))}
                    <Flex fw>
                        <Padding padding="10px 0" onClick={navigateToMarkets}>
                            <DText fontSize="m" color="#ffc800" style={{ cursor: "pointer" }}>
                                <Text tid="all-markets" />
                            </DText>
                        </Padding>
                    </Flex>
                </Padding>
            </CFlex>
        </>

    )
}

const DesktopTable = ({item, currency, navigateToTrade}) => {

    const { main: {lang} } = useMainContext()
    const change = item.changes["24h"].changePresent

    return (
        <Row onClick={() => navigateToTrade(item)}>
            <Column width="25%">
                <img src={
                    SOCKET_URL +
                    `assets/icon/${item?.coin?.toLowerCase()}.png`}
                     width={28}
                     alt=" "
                />
                <DText fontSize="m" main>
                    <Padding padding="0 10px">
                        {lang === "en" ? item.name : item.fa}
                    </Padding>
                </DText>
            </Column>

            <Column width="25%">
                <DText fontSize="m" type="number" main>
                    {formatNumber(
                        currency === "IRT" ? item.buy : item.value
                    )}
                </DText>
            </Column>

            <Column width="25%" justify="center">
                <DText fontSize="m" color={change < 0 ? "#e9106c" : "#1ce087"} _type="number">
                    {change}%
                </DText>
            </Column>

            <Column width="25%" justify="center">
                <img src={SOCKET_URL + `graphs/${item.coin}.svg`}
                     alt=" "
                />
            </Column>
        </Row>
    )
}

const MobileTable = ({item, currency, navigateToTrade}) => {

    const { main: {lang, theme} } = useMainContext()
    const {width} = useWindowSize()
    const change = item.changes["24h"].changePresent

    return (
        <Row onClick={() => navigateToTrade(item)}>
            <CFlex align="flex-start" width={width > 480 ? "50%" : "100%"}>
                <Column>
                    <img src={
                        SOCKET_URL +
                        `assets/icon/${item?.coin?.toLowerCase()}.png`}
                         width={28}
                         alt=" "
                    />
                    <DText fontSize="m" main>
                        <Padding padding="0 10px">
                            {lang === "en" ? item.name : item.fa}
                        </Padding>
                    </DText>
                </Column>
                <RMargin margin="0 8px" />
                <Flex fw justify="space-between">
                    <DText fontSize="m">
                        <Text tid="last-price" />:
                    </DText>
                    <Column>
                        <DText fontSize="m" type="number" main>
                            {formatNumber(
                                currency === "IRT" ? item.buy : item.value
                            )}
                        </DText>
                    </Column>
                </Flex>

                <Flex fw justify="space-between">
                    <DText fontSize="m">
                        <Text tid="24change" />:
                    </DText>
                    <Column>
                        <DText fontSize="m" color={change < 0 ? "#e9106c" : "#1ce087"} _type="number">
                            {change}%
                        </DText>
                    </Column>
                </Flex>
            </CFlex>
            <Flex width={width > 480 ? "50%" : "100%"} style={{ 
                margin: width < 480 && "15px 0"
            }}>
                <img src={SOCKET_URL + `graphs/${item.coin}.svg`}
                     width={width > 4809 ? "80%" : "60%"}
                     alt=" " />
            </Flex>
        </Row>
    )
}

const headers = [
    "name", "last-price", "24change", "24chart"
]

export default PriceTable;
import MainLayout from "../components/layouts/MainLayout";
import Banner from "../components/main/Banner";
import PriceTable from "../components/main/PriceTable";
import CardsSlider from "../components/main/CardsSlider";
import TradingWidget from "../components/main/TradingWidget";
import Cards from "../components/main/Cards";
import TradeEverywhere from "../components/main/TradeEverywhere";
import MobileMoc from "../components/main/MobileMoc";
import LandingOtc from "../components/main/LandingOtc";
import AnimatedMobile from "../components/main/AnimatedMobile";
import {useEffect, useState} from "react";
import CryptoPriceTable from "../components/main/CryptoPriceTable";


const Landing = () => {

    const [offsetY, setOffsetY] = useState(0)
    const handleScroll = () => setOffsetY(window.scrollY)


    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <MainLayout >
            <Banner />
            <TradingWidget type={"ticket"} />
            <CardsSlider />
            <TradingWidget />
            <LandingOtc />
            <PriceTable />
            <AnimatedMobile offsetY={offsetY} />
            <CryptoPriceTable />
            <TradeEverywhere />
            <Cards />
            <MobileMoc />
        </MainLayout>
    )
}

export default Landing;

import styled from "styled-components";

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 280px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1050px) {
    grid-template-columns: repeat(2, 280px);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 280px);
  }
`


const Card = styled.div`
  border-radius: 8px;
  height: 220px;
  background-color: ${props => props.theme.mainBg};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover{
    background-image: linear-gradient(45deg, ${props => props.theme.clientMain} 0%, ${props => props.theme.clientPrimary}60 100%);
    transform: scale(1.05);
    }
`

const CardContentBox = styled.div`
  overflow-y: hidden;
  width: 100%;
  height: 140px;
`


export {
    CardGrid,
    Card,
    CardContentBox
}
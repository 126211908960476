import axios from 'axios'
import { BASE_URL } from '../../utils/constants'

const deleteApi = (token, path) => {
	return axios.delete(BASE_URL + path, {
		headers: {
			'x-auth-token': token,
		},
	})
}

export { deleteApi }

export const formatDate = (date, type, lang) => {
    if (type === "time") {
        return new Date(date).toLocaleTimeString(lang);
    }
    return new Date(date).toLocaleDateString(lang);
};

export const variants = {
    in: {
        opacity: 1, transition: { duration: 0.3 }
    },
    out: {
        opacity: 0, transition: { duration: 0.3 }
    }
}


export const formatNumber = (num, point) => {
    if (!num) {
        return 0
    }
    const float = parseFloat(num)
    if (Math.abs(float) < 0.000001) return float.toFixed(8)
    if (Math.abs(float) < 0.01) return float.toFixed(point ? point : 6)
    if (Math.abs(float) < 1) return float.toFixed(point ? point : 6)
    if (Math.abs(float) < 1000) return float.toFixed(point ? point : 1)
    return Math.round(float).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatSize = (size) => {
    if (size < 1000) return `${size}B`
    if (size < 1000000) return `${(size / 1000).toFixed(2)}KB`
    else return `${(size / 1000000).toFixed(2)}MB`
}

/**
 * String to number
 * @param {string} str
 * @returns {null|number}
 */
export const stringToNumber = (str) => {
    try {
        let numParts = str.split('.')
        numParts[0] = numParts[0].replaceAll(",", "")
        return parseFloat(numParts.join("."))
    } catch (err) { return 0 }
}


/**
 * @param e - event
 * @param runAfterUpdate
 * @return {*}
 */
export const onInputValueChangeUtil = (e, runAfterUpdate) => {
    let cursorStart = e?.target?.selectionStart
    let cursorEnd = e?.target?.selectionEnd
    let val = e?.target?.value
    let numParts = val.split('.')
    const originalLen = numParts[0].length
    numParts[0] = numParts[0].replaceAll(",", "")
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    const newLen = numParts[0].length

    runAfterUpdate(() => {
        if (newLen > originalLen) {
            cursorStart++
            cursorEnd++
        }else if (newLen < originalLen) {
            cursorStart--
            cursorEnd--
        }
        e?.target?.setSelectionRange(cursorStart, cursorEnd)
    })

    return (numParts.join("."))
}

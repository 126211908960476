import { Flex, Margin,} from "../../styles/common/CommonStyles";
import Text from "../../utils/Text";
import {CFlexStart, FlexCenter, FlexStart, LineBreak, Network, Tooltip, DText} from "../../styles/components/InvoiceStyles";
import {useProfileQuery} from "../../services/react-query/useProfileQuery";
import FilterDropdown from "../dropdowns/FilterDropdown";
import {useEffect, useState} from "react";
import {AddIcon} from "../../styles/components/InvoiceStyles"
import {HOME} from "../../utils/constants";
import {FaStop} from "react-icons/fa";
import {formatNumber, stringToNumber} from "../../utils/common";
import {useWindowSize} from "../../hooks/useWindowSize";


const InvoiceNetworks = ({
    coin,
    networks,
    type,
    networkData,
    networkOperation,
    invoiceData
}) => {

    const { width } = useWindowSize()
    const {data: profile} = useProfileQuery()
    const [showTooltip, setShowTooltip] = useState(false)
    const [wallets, setWallets] = useState([])
    const [banks, setBanks] = useState([])

    useEffect(() => {
        if (profile) {
            const _wallets = profile.wallets.filter(w => w.coin === coin)
            if (_wallets.length) {
                const addresses = _wallets.map(w => w.address)
                setWallets(addresses)
            }
            setBanks(profile.banks.map(b => b.shebaNo))
        }
    }, [profile])

    const onOptionChanged = (idx) => {
        if (type === "buy") {
            const address = wallets[idx]
            const wallet = profile?.wallets.find(w => w.address === address)
            networkOperation("change-wallet", wallet)
        }else {
            const bank = profile?.banks[idx]
            networkOperation("change-bank", bank)
        }
    }


    return(
        <>

            <FlexStart width="100%">
                <DText fontSize="0.8rem">
                    <Text tid="choose-network"/>
                </DText>
            </FlexStart>
            <Flex fw justify="flex-start" style={{flexWrap: "wrap"}}>
                {networks.map(
                    (network, idx) => (
                        <Network
                            active={networkData.selected === idx}
                            onClick={() => networkOperation("change-network", idx)}
                        >
                            {network.network}
                        </Network>
                    ))}
            </Flex>

            <Margin margin="5px 0"/>
            <FlexStart width="100%">
                <DText fontSize="0.8rem">
                    <Text tid="deposit-to" />
                </DText>
            </FlexStart>
            <Margin margin="5px 0"/>
            <FlexStart width="100%" style={{position: "relative"}}>
                <FilterDropdown
                    options={type === "buy" ? wallets : banks}
                    defaultOption={type === "buy" ? "choose-wallet" : "choose-bank"}
                    onOptionChanged={onOptionChanged}
                    width="100%"
                    margin="0"
                />
                {width > 480 &&
                    <>
                        <AddIcon
                            size={18}
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            onClick={() => window.location.href = HOME + "user/accounts-cards"}
                        />
                        <Tooltip top="-40px" left={0} show={showTooltip}>
                            <Text tid="add-wallet" />
                        </Tooltip>
                    </>
                }

            </FlexStart>
            <Margin margin="10px 0" />

            {invoiceData.showNote &&
                <FlexCenter style={{ margin: '25px 0' }}>
                    <FaStop
                        color='#ffc800'
                        size={16}
                        style={{ transform: 'rotate(45deg)', margin: '0 10px' }}
                    />
                    <CFlexStart>
                        <DText>
                            <Text tid="max-receive-note" />
                            {parseInt(stringToNumber(invoiceData.totalPair) / invoiceData.receiveMax)}
                            <Text tid="day-receive" />
                        </DText>
                        <FlexStart>
                            <DText color='#ffc800'>
                                <Text tid="max-amount-per-day" />
                            </DText>
                            <DText style={{ margin: "3px 5px 0 5px" }}>
                                {formatNumber(invoiceData.receiveMax)}
                            </DText>
                        </FlexStart>
                    </CFlexStart>
                </FlexCenter>
            }
            <LineBreak />
            <Margin margin="10px 0" />

        </>
    )
}

export default InvoiceNetworks;
import {useMainContext} from "../../contexts/main";
import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../fetch-api/get";
import {useQueryContext} from "../../contexts/query";
import {putApi} from "../fetch-api/put";


const useUnreadNotificationQuery = (page) => {
    const {
        profile: { token },
    } = useMainContext()

    return useQuery(
        'fetch-unread-notifications',
        () => normalFetch(token, `notifications/unread?page=${page}&limit=5`),
        {
            enabled: !!token,
            refetchOnWindowFocus: false,
            select: (res) => res.data,
        }
    )
}


const useReadNotificationMutation = () => {
    const {
        profile: { token },
    } = useMainContext()
    const { queryClient } = useQueryContext()

    return useMutation((id) => putApi({}, token, `notifications/read/${id}`), {
        onSuccess: () => {
            queryClient.invalidateQueries('fetch-unread-notifications')
        },
    })
}


export {
    useUnreadNotificationQuery,
    useReadNotificationMutation
}
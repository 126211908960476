import {HOME, SOCKET_URL} from "../../utils/constants";
import { useMainContext } from "../../contexts/main";
import {
    Margin
} from "../../styles/common/CommonStyles";
import {
    WalletWrapper,
    WalletTab,
    PriceBoard,
    ReceiveBoard,
    Wrapper,
    ImageWrapper,
    Header, InvoiceWrapper,
    Button, CFlexCenter,
    FlexEnd, FlexStart,
    FlexCenter, FlexBetween,
    InvoiceText, LoaderContainer,
    Decoration, DText
} from "../../styles/components/InvoiceStyles"
import Text from "../../utils/Text";
import { useWageCalculator} from "../../services/react-query/useOtcQuery"
import {formatNumber, stringToNumber} from "../../utils/common"
import { PulseLoader } from "react-spinners";
import {useAvailableCoin} from "../../services/react-query/useOtcQuery";
import {useEffect, useState} from "react";
import {useSocketContent} from "../../contexts/socket-content";
import Loading from "./Loading";
import {EqualSvg} from "../../utils/constants";
import InvoiceNetworks from "./InvoiceNetworks";
import {getCalculatedWages, getExtraWages} from "../../utils/otc";
import {useOtcInvoiceHook} from "../../hooks/useInvoiceHook";
import {useWindowSize} from "../../hooks/useWindowSize";



/**
 * @param {Object} data - main data - p2pData / otcData
 * @param {string} type - buy / sell
 * @param {number} tab - p2p tab number - limit / market / stop-limit / oco
 * @param {Function} onClose - on Invoice dismissed
 * @param {string} tradeType - p2p / otc
 * @param {string} otcType - otc type - market / limit
 * @param {Object} networks - networks object => have 2 list {deposit[], withdraw[]}
 * @return {JSX.Element}
 * @constructor
 */
const Invoice = ({
                     data, type,
                     tab, onClose,
                     tradeType = "p2p",
                     otcType = null,
                     networks = null
} = {}) => {

    const { profile: {token} } = useMainContext()
    const {width} = useWindowSize()
    const { data: wages, mutate: calculateWages } = useWageCalculator()
    const socketContent = useSocketContent()

    const initialState = {
        totalPair: "", totalValue: "",
        feePair: "", feeValue: "",
        feeOffPair: "", feeOffValue: "",
        affPair: "0", affValue: "0",
        receivePair: "", receiveValue: "",
        loading: true, added: false
    }

    const [invoiceCache, setInvoiceCache] = useState(initialState)
    const [extraWages, setExtraWages] = useState([])
    const [invoiceData, setInvoiceData] = useState(initialState)

    useEffect(() => {
        let body = {
            amount: stringToNumber(data.amount),
            coin: data.coin,
            pair: "irt",
            section: tradeType,
            type
        }
        calculateWages(body)
    }, [])


    useEffect(() => {
        if (wages) {
            const invoiceData = getCalculatedWages(
                type, tradeType, wages.data.data, data, socketContent
            )
            setInvoiceCache(invoiceData)
            setInvoiceData(invoiceData)
        }

    }, [wages])


    const {
        activeWallet,
        setActiveWallet,
        networkData,
        otcLoading,
        onOtcContinue,
        networkOperation
    } = useOtcInvoiceHook(
        data, type, otcType, networks, onClose
    )


    useEffect(() => {
        if (networks) {
            const extra = getExtraWages(networks, data, type)
            setExtraWages(extra)
        }
    }, [networks])



    useEffect(() => {
        if (activeWallet === 0) {
            setInvoiceData(invoiceCache)
        }
    }, [activeWallet])

    useEffect(() => {
        if (networkData.selected !== null && extraWages.length && !invoiceCache.added) {
            const current = extraWages[networkData.selected]
            let netInvoiceData = {
                ...invoiceCache,
                feePair: formatNumber(stringToNumber(invoiceCache.feePair) + current.fee),
                feeValue: formatNumber(stringToNumber(invoiceCache.feeValue) + current.feeValue),
                added: true,
                showNote: current.showNote,
                receiveMax: current.receiveMax,
                receivePair: formatNumber(stringToNumber(invoiceCache.receivePair) - current.fee),
                receiveValue: formatNumber(stringToNumber(invoiceCache.receiveValue) - current.feeValue)
            }
            setInvoiceData(netInvoiceData)
        }
    }, [networkData])


    const getInvoiceText = (reverse = false) => {
        if (reverse) {
            if (type === "buy") {
                return "IRT"
            }else {
                return data.coin?.toUpperCase()
            }
        }else {
            if (type === "buy") {
                return data.coin?.toUpperCase()
            }else{
                return "IRT"
            }
        }
    }

    const onContinueClicked = () => {
        if (!token) {
            window.location.href = HOME + "user/register-signin"
        }
        onOtcContinue()
    }


    return (
        <Wrapper>
            <InvoiceWrapper>
                {!invoiceData.loading &&
                    <>
                        <ImageWrapper>
                            <img
                                src={SOCKET_URL + `assets/icon/${
                                    data.coin
                                }.png`}
                                alt=" "
                                width={70}
                            />
                        </ImageWrapper>
                        <CFlexCenter height="unset" style={{ padding: width < 768 && "20px 0" }}>
                            <Header><Text tid="invoice" />{" "}<Text tid={type} /></Header>
                            <Margin margin="15px 0" />
                            {tradeType === "otc" &&
                                <>
                                    <FlexCenter>
                                        <DText><Text tid={type === "buy" ? "choose-wallet" : "choose-bank"} /></DText>
                                    </FlexCenter>
                                    <Margin margin="5px 0" />
                                    <WalletWrapper>
                                        {getWalletTabs(type).map((tab, idx) => (
                                            <WalletTab
                                                active={idx === activeWallet}
                                                onClick={() => setActiveWallet(idx)}
                                            >
                                                <FlexCenter height="100%">
                                                    <Text tid={tab} />
                                                </FlexCenter>
                                            </WalletTab>
                                        ))}
                                    </WalletWrapper>
                                    <Margin margin="5px 0" />
                                </>
                            }
                            <PriceBoard>
                                <CFlexCenter>
                                    {(activeWallet === 1 && networks) &&
                                        <InvoiceNetworks
                                            coin={data.coin}
                                            networks={networks[type === "buy" ? "withdraw" : "deposit"]}
                                            type={type}
                                            networkData={networkData}
                                            networkOperation={networkOperation}
                                            invoiceData={invoiceData}
                                        />
                                    }
                                    {invoiceKeys.map(key => (
                                        <FlexBetween>
                                            <InvoiceText>
                                                <Text tid={key} />
                                            </InvoiceText>
                                            <FlexCenter width="unset">
                                                <FlexStart width="unset">
                                                    <InvoiceText color="#ffc800">
                                                        {getInvoiceText()}
                                                    </InvoiceText>
                                                    <InvoiceText color="#ffc800">
                                                        {invoiceData[key + (type === "buy" ? "Value" : "Pair")]}
                                                    </InvoiceText>
                                                </FlexStart>
                                                <FlexCenter width="unset" >
                                                    <EqualSvg />
                                                </FlexCenter>
                                                <FlexEnd  width="unset">
                                                    <InvoiceText >
                                                        {getInvoiceText(true)}
                                                    </InvoiceText>
                                                    <InvoiceText>
                                                        {invoiceData[key + (type === "buy" ? "Pair" : "Value")]}
                                                    </InvoiceText>
                                                </FlexEnd>
                                            </FlexCenter>
                                        </FlexBetween>
                                    ))}
                                </CFlexCenter>
                            </PriceBoard>

                            <Margin margin="10px 0" />
                            <ReceiveBoard>
                                <FlexBetween>
                                    <FlexBetween>
                                        <InvoiceText color="#02cb70">
                                            <Text tid="receive-unit" />
                                        </InvoiceText>
                                        <FlexCenter width="250px">
                                            <FlexStart width="45%">
                                                <InvoiceText color="#02cb70">
                                                    {getInvoiceText()}
                                                </InvoiceText>
                                                <InvoiceText color="#02cb70">
                                                    {invoiceData[ "receive" + (type === "buy" ? "Value" : "Pair")]}
                                                </InvoiceText>
                                            </FlexStart>
                                            <FlexCenter width="10%">
                                                <EqualSvg />
                                            </FlexCenter>
                                            <FlexEnd width="45%">
                                                <InvoiceText>
                                                    {getInvoiceText(true)}
                                                </InvoiceText>
                                                <InvoiceText>
                                                    {invoiceData["receive" + (type === "buy" ? "Pair" : "Value")]}
                                                </InvoiceText>
                                            </FlexEnd>
                                        </FlexCenter>
                                    </FlexBetween>
                                </FlexBetween>
                            </ReceiveBoard>
                            <Margin margin="40px 0 0 0" />
                            <FlexCenter>
                                <Decoration style={{margin: "0 8px"}} />
                                <InvoiceText color="#ffc800"><Text tid="invoice-desc" /></InvoiceText>
                            </FlexCenter>
                            <Margin margin="40px 0 0 0" />
                            <FlexCenter>
                                <Button onClick={onClose}><Text tid="cancel" /></Button>
                                <Button onClick={onContinueClicked} active>
                                    {(otcLoading) ?
                                        <LoaderContainer>
                                            <PulseLoader size={9} color='#191c20' />
                                        </LoaderContainer>
                                        :
                                        <Text tid="continue" />
                                    }
                                </Button>
                            </FlexCenter>
                        </CFlexCenter>
                    </>
                }
            </InvoiceWrapper>
            <Loading loading={invoiceData.loading} />
        </Wrapper>
    )
}

const invoiceKeys = [
    "total", "fee", "feeOff", "aff"
]

const getWalletTabs = (type) => ([
    type === "buy" ? "inside-wallet" : "inside-bank",
    type === "buy" ? "outside-wallet" : "outside-bank"
])




export default Invoice;
import {CFlex, CMargin, Flex, Grid, Padding} from "../../styles/common/CommonStyles";
import {Label, OtcBtn, StyledNumberFormat, ProOrderWrapper} from "../../styles/components/OtcStyles";
import {useMainContext} from "../../contexts/main";
import {useEffect, useState} from "react";
import {formatNumber, onInputValueChangeUtil, stringToNumber} from "../../utils/common";
import {useSocketContent} from "../../contexts/socket-content";
import {useProfileQuery} from "../../services/react-query/useProfileQuery";
import {useWindowSize} from "../../hooks/useWindowSize";
import {useQueryContext} from "../../contexts/query";
import {useAvailableCoin} from "../../services/react-query/useOtcQuery";
import {HOME, MOBILE_SIZE, TABLET_SIZE} from "../../utils/constants";
import {useRunAfterUpdate} from "../../hooks/useRunAfterUpdate";
import Text from "../../utils/Text";
import FilterDropdown from "../dropdowns/FilterDropdown";
import Invoice from "../common/Invoice";
import ModalLayout from "../layouts/ModalLayout";


const ERRORS = {
    en: {max: "Total price is greater than ", min: "Total price is less than "},
    fa: {max: "قیمت انتخابی بیشتر از سقف سفارش گذاری است. سقف سفارش: ", min: "قیمت انتخابی کمتر از کف سفارش گذاری است. کف سفارش: "},

}


const LandingOtc = () => {

    const [activeTab, setActiveTab] = useState(0)
    const {main: {lang}, profile: {token}} = useMainContext()

    const initialState = {
        buy: {
            amount: "", upper: "", lower: "", price: "", coin: "btc", total: ""
        },
        sell: {
            amount: "", upper: "", lower: "", price: "", coin: "btc", total: ""
        }
    }
    const [otcData, setOtcData] = useState(initialState);

    const [limits, setLimits] = useState({
        min: "", max: ""
    })

    const [networks, setNetworks] = useState()

    const limitErrorInitial = {
        error: false, type: "", value: "", userError: false
    }
    const [limitsError, setLimitsError] = useState(limitErrorInitial)

    const [selectedCoin, setSelectedCoin] = useState(null)
    const [tradeType, setTradeType] = useState("buy");
    const [invoice, setInvoice] = useState(false)

    const { prices: market } = useSocketContent()
    const {data: available} = useAvailableCoin()
    const {data: profile} = useProfileQuery()
    const { width } = useWindowSize()
    const {setToast} = useQueryContext()


    useEffect(() => {
        if (market) setSelectedCoin(market.find(c => c.id === otcData[tradeType].coin))
    }, [market])


    useEffect(() => {
        if (available) {
            const details = available.find(a => a.id === otcData[tradeType].coin)
            setLimits({max: details?.otc?.max, min: details?.otc?.min})
            setNetworks({
                deposit: details.depositList, withdraw: details.withdrawList
            })
        }
    }, [available, selectedCoin])


    useEffect(() => {
        const amount = stringToNumber(otcData[tradeType].amount)

        let userBalance;
        if (tradeType === "buy") {
            userBalance = profile?.balance / stringToNumber(otcData[tradeType].price)
        }else {
            const userCoin = profile?.coins.find(c => c.coin === otcData[tradeType].coin)
            userBalance = userCoin?.amount
        }
        if (isNaN(amount)) {
            setLimitsError({error: true, type: "min", value: limits.min, userError: false})
            return
        }

        if (amount > limits.max || amount > userBalance) {
            setLimitsError({error: true, type: "max", value: limits.max, userError: amount > userBalance})
        }else if (amount < limits.min) {
            setLimitsError()
        }else{
            setLimitsError(initialState)
        }
    }, [otcData])


    useEffect(() => {
        if (market) {
            setOtcData(initialState)
            setSelectedCoin(market.find(c => c.id === "btc"))
        }
    }, [activeTab])



    const createOrder = () => {
        if (!token) {
            window.location.href = HOME + "user/register-signin"
        }else if (limitsError.error && !limitsError.userError) {
            setToast({
                isError: true, show: true,
                message: ERRORS[lang][limitsError.type] + formatNumber(limits[limitsError.type])
            })
        }else if (limitsError.userError){
            window.location.href = HOME + "user/wallet-operations"
        }else {
            setInvoice(true)
        }
    }


    const onInvoiceClosed = () => {
        setInvoice(false)
    }

    const runAfterUpdate = useRunAfterUpdate()
    const onAmountChange = (e) => {
        const amount = onInputValueChangeUtil(e, runAfterUpdate)
        let total = 0;
        let price = 0;
        if (selectedCoin) {
            price = selectedCoin[tradeType]
            total = stringToNumber(amount) * price
        }
        setOtcData(state => ({ ...state, [tradeType]: { ...state[tradeType], amount,  total: formatNumber(total), price: formatNumber(price)} }))
    }

    const onTotalPriceChange = (e) => {
        const total = onInputValueChangeUtil(e, runAfterUpdate)
        let amount = 0;
        let price = 0;
        if (selectedCoin) {
            price = selectedCoin[tradeType]
            amount = stringToNumber(total) / price
        }
        setOtcData(state => ({ ...state, [tradeType]: { ...state[tradeType], amount: formatNumber(amount),  total, price: formatNumber(price) }}))
    }

    const onSelectOptionChange = (idx) => {
        setSelectedCoin(market[idx])
        setOtcData(state => ({...state,
            buy: { amount: "", total: "", upper: "", lower: "", coin: market[idx].id },
            sell: { amount: "", total: "", upper: "", lower: "", coin: market[idx].id }
        }))
        setLimitsError(limitErrorInitial)
    }

    const onBoundsChange = (e, type) => {
        const bound = onInputValueChangeUtil(e, runAfterUpdate)
        setOtcData(state => ({...state, [tradeType]: {...state[tradeType], [type]: bound}}))
    }

    const onActionBtnClicked = (type) => {
        if (type === "buy") {
            if (tradeType === "buy") {
                createOrder()
            }else {
                setTradeType("buy")
            }
        }else {
            if (tradeType === "sell") {
                createOrder()
            }else {
                setTradeType("sell")
            }
        }
    }

    return(
        <>
            <Flex fw>
                <Padding padding="60px 0">
                    <CFlex fw>
                        {activeTab === 0 ?
                            <Grid width={width > TABLET_SIZE ? "60%" : "90%"} gc={3} cgap="20px" >
                                <CFlex>
                                    <Label>
                                        <Text tid="unit" />
                                    </Label>
                                    <StyledNumberFormat
                                        value={otcData[tradeType].amount}
                                        onChange={onAmountChange}
                                        placeholder={
                                            lang === "us"
                                                ? "Please enter an amount"
                                                : "لطفا یک مقدار وارد کنید"
                                        }
                                    />
                                </CFlex>
                                <CFlex>
                                    <Label onTypePro={true}>
                                        <Text tid="price" />
                                    </Label>
                                    <StyledNumberFormat
                                        placeholder={lang === "us" ? "Price" : "قیمت"}
                                        value={otcData[tradeType].total}
                                        onChange={onTotalPriceChange}
                                    />
                                </CFlex>
                                <CFlex>
                                    <Label>
                                        <Text tid="coin" />
                                    </Label>
                                    {market &&
                                        <FilterDropdown
                                            width="100%"
                                            heigth="48px"
                                            margin="0"
                                            options={market}
                                            defaultOption={0}
                                            type={tradeType}
                                            onOptionChanged={onSelectOptionChange}
                                            isCoin
                                        />
                                    }
                                </CFlex>
                            </Grid>
                            :
                            <ProOrderWrapper>
                                <CFlex fw>
                                    <Label>
                                        <Text tid="coin" />
                                    </Label>
                                    {market &&
                                        <FilterDropdown
                                            width="100%"
                                            heigth="48px"
                                            margin="0"
                                            options={market}
                                            defaultOption={0}
                                            type={tradeType}
                                            onOptionChanged={onSelectOptionChange}
                                            isCoin
                                        />
                                    }
                                </CFlex>
                                <CFlex fw>
                                    <Label>
                                        <Text tid="unit" />
                                    </Label>
                                    <StyledNumberFormat
                                        value={otcData[tradeType].amount}
                                        onChange={onAmountChange}
                                        placeholder={
                                            lang === "us"
                                                ? "Please enter an amount"
                                                : "لطفا یک مقدار وارد کنید"
                                        }
                                    />
                                </CFlex>
                                <CFlex fw>
                                    <Label onTypePro={true}>
                                        <Text tid="price" />
                                    </Label>
                                    <StyledNumberFormat
                                        placeholder={lang === "us" ? "Price" : "قیمت"}
                                        value={otcData[tradeType].total}
                                        onChange={onTotalPriceChange}
                                    />
                                </CFlex>
                                <Grid fw gc={2} cgap="5px">
                                    <CFlex>
                                        <Label>
                                            <Text tid="lowerbound" />
                                        </Label>
                                        <StyledNumberFormat
                                            typeOnPro
                                            placeholder={lang === "us" ? "Price" : "قیمت"}
                                            value={otcData[tradeType].lower}
                                            onChange={(e) => onBoundsChange(e, "lower")}
                                        />
                                    </CFlex>
                                    <CFlex>
                                        <Label>
                                            <Text tid="upperbound" />
                                        </Label>
                                        <StyledNumberFormat
                                            typeOnPro
                                            placeholder={lang === "us" ? "Price" : "قیمت"}
                                            value={otcData[tradeType].upper}
                                            onChange={(e) => onBoundsChange(e, "upper")}
                                        />
                                    </CFlex>

                                </Grid>
                            </ProOrderWrapper>

                        }

                        <CMargin margin="10px" />
                        <Flex fw style={{ flexDirection: width > MOBILE_SIZE ? "row" : "column" }}>
                            <OtcBtn active={tradeType === "buy"} onClick={() => onActionBtnClicked("buy")}>
                                <Text tid="buy-order" />
                            </OtcBtn>
                            <OtcBtn active={tradeType === "sell"} onClick={() => onActionBtnClicked("sell")}>
                                <Text tid="sell-order" />
                            </OtcBtn>
                            <OtcBtn tab onClick={() => setActiveTab(state => state === 0 ? 1 : 0)}>
                                <Text tid={activeTab === 0 ? "limit-req" : "market-req"} />
                            </OtcBtn>
                        </Flex>
                    </CFlex>
                </Padding>
            </Flex>
            <ModalLayout
                open={invoice}
                onClose={onInvoiceClosed}
                width="90%"
            >
                <Invoice
                    data={otcData[tradeType]}
                    networks={networks}
                    type={tradeType}
                    tradeType="otc"
                    onClose={onInvoiceClosed}
                    otcType={activeTab === 0 ? "market" : "limit"}
                />
            </ModalLayout>
        </>

    )
}


export default LandingOtc;
import {Background, CFlex, CMargin, DText, Flex, Padding} from "../../styles/common/CommonStyles";
import {useInView} from "react-intersection-observer";
import {useEffect, useRef, useState} from "react";
import {
    AMTitle,
    Img,
    ScrollAnimationWrapper, TickIcon,
    Translate,
    TranslateWrapper,
    AMDesc
} from "../../styles/components/AnimatedMobileStyles";
import useScrollAnimation from "../../utils/useScrollAnimation";
import Text from "../../utils/Text";



const AnimatedMobile = ({
    offsetY
}) => {

    const [topY, setTopY] = useState(null)
    const [moved, setMoved] = useState(0)

    const mobileRef = useRef()

    const { ref, inView } = useInView()

    const resetCalculations = () => {
        setTopY(ref.current?.offsetTop)
    }

    useEffect(() => {
        resetCalculations()
    }, [])


    const TOP = mobileRef.current?.getBoundingClientRect().top
    const windowHeight = window.innerHeight

    useEffect(() => {
        window.addEventListener('resize', resetCalculations)

        return () => window.removeEventListener('resize', resetCalculations)
    }, [])


    useEffect(() => {
        if (TOP < windowHeight && TOP > 0) {
            setMoved(windowHeight - TOP)
        }
    }, [TOP, windowHeight])


    useEffect(() => {
        if (topY && offsetY && inView) {
            setMoved(offsetY - topY)
        }
    }, [topY, offsetY])

    const { ref: titleRef, control: titleControl } = useScrollAnimation(0.2)
    const { ref: descRef, control: descControl } = useScrollAnimation(0.2)

    return (
        <>
        <Background bg='primaryBg' style={{ background: '#061121' }}>
            <Padding padding='120px 0'>
                <CFlex fw>
                    <ScrollAnimationWrapper fw height='80px' ref={titleRef}>
                        <AMTitle
                            variants={titleVariants}
                            initial='out'
                            animate={titleControl}
                        >
                            <Text tid='animate-mobile-title' />
                        </AMTitle>
                    </ScrollAnimationWrapper>

                    <ScrollAnimationWrapper fw height='120px' ref={descRef}>
                        <AMDesc
                            variants={descVariants}
                            initial='out'
                            animate={descControl}
                        >
                            {descriptions.map(desc => (
                                <Flex>
                                    <TickIcon size={24} />
                                    <DText fontSize='b' primary>
                                        <Text tid={desc} />
                                    </DText>
                                </Flex>
                            ))}
                        </AMDesc>
                    </ScrollAnimationWrapper>

                    <CMargin margin='20px' />
                    <TranslateWrapper ref={mobileRef}>
                        <Translate
                            ref={ref}
                            style={{transform: getTransform(moved)}}
                        >
                            <Img src={require('../../assets/images/platforms/animate-mobile.png')} />
                        </Translate>
                    </TranslateWrapper>
                </CFlex>
            </Padding>
        </Background>
        </>
    )
}


const titleVariants = {
    in: {
        y: 0, transition: {duration: 0.2, ease: 'easeOut'}
    },
    out: {
        y: 100
    }
}

const descVariants = {
    in: {
        y: 0, opacity: 1, transition: {duration: 0.3}
    },
    out: {
        y: 40, opacity: 0
    },
}


const getTransform = (pos) => {

    if (pos === 0) return `translateZ(0px) rotateX(0deg)`

    let rotation = pos / 13
    if (rotation > 50) {
        rotation = 50
    }
    if (rotation < 0) {
        rotation = 0
    }

    return `translateZ(0px) rotateX(${50 - rotation}deg)`
}


const descriptions = [
    'animate-mobile-desc1',
    'animate-mobile-desc2',
    'animate-mobile-desc3'
]


export default AnimatedMobile
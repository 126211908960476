import {createContext, useContext, useEffect, useState} from "react";
import { useSocket } from "./socket";


const SocketContentContext = createContext()

export const useSocketContent = () => {
    return useContext(SocketContentContext)
}

export const SocketContentProvider = ({ children }) => {

    const socket = useSocket()
    const [prices, setPrices] = useState(null)


    /* prices */
    useEffect(() => {
        if (socket) {
            socket.emit("get.prices")
            socket.on("prices", (data) => {
                setPrices(data)
            })
        }

        return () => {
            if (socket) {
                socket.off("prices")
            }
        }
    }, [socket])


    return (
        <SocketContentContext.Provider value={{ prices }}>
            {children}
        </SocketContentContext.Provider>
    )
}
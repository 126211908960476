const getMainTheme = (theme, lang) => {
	const MainTheme = {
		dark: {
			mainBg: '#191c20',
			primaryBg: '#161a1e',
			color: '#c3c5b7',
			primary: '#fff',
			hover: '#26282b60',
			active: '#f0b90b',
			mainOrange: '#ffc800',
			mainRed: '#e9106c',
			mainGreen: '#1ce087',
			clientMain: '#ffc800',
			clientPrimary: '#dcae09',
			inActive: '#72747830',
			search: '#161a1e',
			input: '#3f4243',
			textArea: '#44464c',
			dropdown: '#36383C',
			otcInput: "#0e111a",
			tInputBg: "#2a2d35",
		},
		light: {
			mainBg: '#E5E9F0',
			primaryBg: '#FAFBFC',
			color: '#191c20',
			primary: '#161a1e',
			hover: '#26282b70',
			active: '#f0b90b',
			mainOrange: '#dcae09',
			mainRed: '#e9106c',
			mainGreen: '#15A865',
			clientMain: '#ffc800',
			clientPrimary: '#DFB620',
			inActive: '#72747830',
			search: '#8B8E92',
			input: '#727478',
			textArea: '#44464c',
			dropdown: '#CDCDCE',
			otcInput: "#f0f1f2",
			tInputBg: "#f0f1f2",
		},
	}

	const chosenTheme = { ...MainTheme[theme] }
	chosenTheme.english = lang === 'en'
	return chosenTheme
}

export { getMainTheme }
